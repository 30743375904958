import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container, Nav, NavItem, NavLink, Row, Col, Input } from "reactstrap"
import SEO from "../components/seo"
import CurrencyFormatter from "../components/bigcommerce/CurrencyFormatter"
import { CartContext } from "../context/CartContext"

const Shop = ({ data }) => {
  const { categories, products } = data

  const value = useContext(CartContext)

  const [categoryId, setCategoryId] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])

  useEffect(() => {
    const ids = categories.nodes.map(cat => cat.bigcommerce_id)
    setCategoryId(ids)
  }, [])
  useEffect(() => {
    const myProducts = []
    categoryId.forEach(cat => {
      products.nodes.forEach(item => {
        if (item.categories.includes(cat)) {
          const selected = myProducts.find(
            prod => prod.bigcommerce_id === item.bigcommerce_id
          )
          if (!selected) myProducts.push(item)
        }
      })
    })
    setSelectedProducts(myProducts)
  }, [categoryId])

  const handleCheckCategory = id => {
    if (categoryId.includes(id)) {
      const unChecked = categoryId.filter(cat => cat !== id)
      setCategoryId(unChecked)
    } else {
      setCategoryId(prev => [...prev, id])
    }
  }

  return (
    <Layout>
      <SEO title="Products" description={data.site.siteMetadata.description} />
      <div id="shop">
        <div className="page-headline">
          <div className="container">
            <div className="section-heading text-center">
              <h1 className="text-purple">
                <strong>PRODUCTS</strong>
              </h1>
            </div>
          </div>
        </div>
        <div className="page-content">
          <Container>
            <div className="product-tab">
              <Row className="align-items-center mb-5">
                <Col xs="auto">
                  <Nav tabs className="mb-0 ml-0">
                    <NavItem>
                      <NavLink>
                        <i
                          className="fa fa-th text-purple"
                          aria-hidden="true"
                        />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>
                        <i
                          className="fa fa-th-list text-purple"
                          aria-hidden="true"
                        />
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col md={4}>
                  <p className="text-purple">Product Compare(0)</p>
                </Col>
                <Col className="ml-auto" md={4}>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <p className="text-purple text-uppercase">Sort By:</p>
                    </Col>
                    <Col>
                      <Input type="select" name="select" id="exampleSelect">
                        <option>{"Default"}</option>
                        <option>{"Name (A - Z)"}</option>
                        <option>{"Name (Z - A)"}</option>
                        <option>{"Price (Low > High)"}</option>
                        <option>{"Price (High > Low)"}</option>
                      </Input>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
            </div>

            <div className="row justify-content-between">
              <div className="col-md-3">
                <div className="side-content">
                  <div className="category-container">
                    <h4 className="text-purple">
                      <strong>SHOP</strong>
                    </h4>
                    <div className="category-list">
                      {categories.nodes.map(category => {
                        const productLength = products.nodes.filter(prod =>
                          prod.categories.includes(category.bigcommerce_id)
                        ).length

                        const isSelected = categoryId.includes(
                          category.bigcommerce_id
                        )

                        if (productLength === 0) return null
                        return (
                          <div
                            className="form-group mt-0 mb-1"
                            key={category.bigcommerce_id}
                          >
                            <label htmlFor={category.bigcommerce_id}>
                              {`${category.name} (${productLength})`}
                              <input
                                id={category.bigcommerce_id}
                                type="checkbox"
                                name={category.name}
                                value={category.bigcommerce_id}
                                checked={isSelected}
                                onChange={() =>
                                  handleCheckCategory(category.bigcommerce_id)
                                }
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="row">
                  {selectedProducts.map(product => (
                    <div
                      className="col-12 col-sm-6 col-md-4 mb-4"
                      key={product.bigcommerce_id}
                    >
                      <div class="card h-100">
                        <Link
                          to={`/shop/${product.custom_url.url.replace(
                            /\//g,
                            ""
                          )}`}
                        >
                          <img
                            src={
                              product.images[0].url_zoom ||
                              "https://source.unsplash.com/user/joshhild/500x500"
                            }
                            className="card-img-top"
                            alt={product.bigcommerce_id}
                          />
                        </Link>
                        <div class="card-body text-center">
                          <p className="card-title">
                            <Link
                              className="text-purple-75"
                              to={`/shop/${product.custom_url.url.replace(
                                /\//g,
                                ""
                              )}`}
                            >
                              {product.name}
                            </Link>
                          </p>
                          <p className="price">
                            <span>
                              <CurrencyFormatter
                                currency={"USD"}
                                amount={product.price}
                              />
                            </span>
                          </p>
                          <div className="add-cart-options">
                            <button
                              className="btn w-100"
                              onClick={() =>
                                value &&
                                value.addToCart(
                                  product.bigcommerce_id,
                                  product.variants[0].id,
                                  1
                                )
                              }
                              disabled={
                                value &&
                                value.state.addingToCart ==
                                  product.bigcommerce_id
                              }
                            >
                              <i
                                class="fa fa-shopping-cart mr-2"
                                aria-hidden="true"
                              />
                              {value &&
                              value.state.addingToCart == product.bigcommerce_id
                                ? "adding to cart"
                                : "add to cart"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default Shop

export const shopQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    categories: allBigCommerceCategories {
      nodes {
        bigcommerce_id
        name
      }
    }
    products: allBigCommerceProducts {
      nodes {
        bigcommerce_id
        custom_url {
          url
        }
        name
        images {
          url_thumbnail
          url_zoom
        }
        price
        categories
        variants {
          id
        }
      }
    }
  }
`
